import { array, bool, func, string } from "prop-types";
import React, { useState } from "react";
import { styled } from "styled-components";
import Colors from "../../styles/Colors";
import Summary from "../Summary";
import { Button } from "../Button";
import { Show, Hide } from "../icons";

const Subheader = styled.h2`
    color: ${Colors.greySecondaryText};
    margin: 0;
    line-height: 1.5em;
`;

const VisibilityButtonContainer = styled.div``;

const HSection = styled.section`
    align-self: stretch;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-right: 6px;
`;

const StyledSummaryList = styled.div`
    flex-grow: 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    ${VisibilityButtonContainer} {
        visibility: hidden;
    }

    &:hover {
        ${VisibilityButtonContainer} {
            visibility: visible;
        }
    }
`;

const SummaryListView = ({
    month,
    items,
    defaultIsVisibleAll,
    saveVisibilities,
}) => {
    const [isVisibleAll, setIsVisibleAll] = useState(defaultIsVisibleAll);

    const summaryList = items?.map((item, index) => {
        const { url, savedIsVisible } = item;
        const [isVisible, setIsVisible] = useState(savedIsVisible);
        return { index, url, isVisible, setIsVisible };
    });

    const handleItemVisibilityChange = (isVisible, index) => {
        const { setIsVisible } = summaryList[index];
        const updatedVisibilities = summaryList.map((s, i) =>
            i === index ? isVisible : s.isVisible
        );

        setIsVisible(isVisible);
        setIsVisibleAll(updatedVisibilities.includes(true));

        saveVisibilities(updatedVisibilities);
    };

    const handleVisibleButtonClick = () => {
        const updatedIsVisibleAll = !isVisibleAll;

        summaryList.forEach(({ setIsVisible }) =>
            setIsVisible(updatedIsVisibleAll)
        );
        setIsVisibleAll(updatedIsVisibleAll);

        saveVisibilities(
            new Array(summaryList.length).fill(updatedIsVisibleAll)
        );
    };

    return (
        <StyledSummaryList>
            <HSection>
                <Subheader>Итоги месяца</Subheader>
                <VisibilityButtonContainer>
                    <Button
                        type="link"
                        iconLeft={isVisibleAll ? <Show /> : <Hide />}
                        onClick={handleVisibleButtonClick}
                    />
                </VisibilityButtonContainer>
            </HSection>
            {summaryList.map(({ url, isVisible, index }) => {
                return (
                    <Summary
                        key={index}
                        url={url}
                        month={month}
                        index={index}
                        isVisible={isVisible}
                        handleVisibilityChange={handleItemVisibilityChange}
                    ></Summary>
                );
            })}
        </StyledSummaryList>
    );
};

SummaryListView.propTypes = {
    month: string,
    items: array,
    defaultIsVisibleAll: bool,
    saveVisibilities: func,
};

export default SummaryListView;
