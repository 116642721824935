import React from "react";
import { string } from "prop-types";
import SummaryListView from "./SummaryListView";
import { loadFromLocalStorage, saveToLocalStorage } from "../../helpers/utils";

const SUMMARY_LIST_VISIBILITIES_LOCAL_STORAGE_KEY = "SummaryListVisibilities";
const REVENUE_SUMMARY_PATH = "/revenue/summary";
const RESERVATION_SUMMARY_PATH = "/reservation/summary";
const EXPENSE_PERMANENT_SUMMARY_PATH = "/expense/permanent/summary";
const BUDGET_SUMMARY_PATH = "/budget/summary";
const BUDGET_DAY_SUMMARY_PATH = "/budget/day/summary";
const EXPENSE_VARIABLE_SUMMARY_PATH = "/expense/variable/summary";
const PROFIT_SUMMARY_PATH = "/profit/summary";

const loadVisibilities = (length) => {
    const defaultValue = new Array(length).fill(true);
    try {
        const visibilities = loadFromLocalStorage(
            SUMMARY_LIST_VISIBILITIES_LOCAL_STORAGE_KEY
        );
        return Array.isArray(visibilities) && visibilities.length === length
            ? visibilities
            : defaultValue;
    } catch (error) {
        return defaultValue;
    }
};

const prepareUrls = (month) => {
    const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
    const params = new URLSearchParams({ month });
    return [
        `${apiEndPoint}${REVENUE_SUMMARY_PATH}?${params}`,
        `${apiEndPoint}${RESERVATION_SUMMARY_PATH}?${params}`,
        `${apiEndPoint}${EXPENSE_PERMANENT_SUMMARY_PATH}?${params}`,
        `${apiEndPoint}${BUDGET_SUMMARY_PATH}?${params}`,
        `${apiEndPoint}${BUDGET_DAY_SUMMARY_PATH}?${params}`,
        `${apiEndPoint}${EXPENSE_VARIABLE_SUMMARY_PATH}?${params}`,
        `${apiEndPoint}${PROFIT_SUMMARY_PATH}?${params}`,
    ];
};

const prepareItems = (urls, visibilities) => {
    return urls.map((url, index) => ({
        url,
        savedIsVisible: visibilities?.[index],
    }));
};

const SummaryList = ({ month }) => {
    const urls = prepareUrls(month);
    const visibilities = loadVisibilities(urls.length);
    const defaultIsVisibleAll = visibilities.includes(true);
    const items = prepareItems(urls, visibilities);
    const saveVisibilitiesToLocalStorage = (value) =>
        saveToLocalStorage(SUMMARY_LIST_VISIBILITIES_LOCAL_STORAGE_KEY, value);

    return (
        <SummaryListView
            month={month}
            items={items}
            defaultIsVisibleAll={defaultIsVisibleAll}
            saveVisibilities={saveVisibilitiesToLocalStorage}
        />
    );
};

SummaryList.propTypes = {
    month: string.isRequired,
};

export default SummaryList;
