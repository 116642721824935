import { GoogleOAuthProvider } from "@react-oauth/google";
import { element } from "prop-types";
import React, { useState } from "react";
import { AuthContext } from "./AuthContext";

const postSignIn = async (code) => {
    const path = `/auth/google/signin`;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}${path}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ code }),
                credentials: "include",
            }
        );
        return response.ok;
    } catch (error) {
        console.error(`Error while processing POST ${path}: `, error);
    }
    return false;
};

const postSignOut = async () => {
    const path = `/auth/google/signout`;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}${path}`,
            {
                method: "POST",
                credentials: "include",
            }
        );
        return response.ok;
    } catch (error) {
        console.error(`Error while processing POST ${path}: `, error);
    }
    return false;
};

const AuthProvider = ({ children }) => {
    const [isSignedIn, setIsSignedIn] = useState(false);

    const signIn = async (code) => {
        if (isSignedIn) {
            return;
        }
        setIsSignedIn(await postSignIn(code));
    };

    const signOut = async () => {
        if (!isSignedIn) {
            return;
        }
        setIsSignedIn(!(await postSignOut()));
    };

    const value = { isSignedIn, signIn, signOut };

    return (
        <AuthContext.Provider value={value}>
            <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
            >
                {children}
            </GoogleOAuthProvider>
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: element,
};

export default AuthProvider;
