import React, { useEffect } from "react";
import { element } from "prop-types";
import { useAuth } from "./AuthContext";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
    const { isSignedIn, signIn } = useAuth();

    useEffect(() => {
        let isCleanedUp = false;

        if (!isCleanedUp) {
            if (!isSignedIn) {
            // Calling signIn function without argument
            // will result in an attempt to authenticate
            // with previously saved authentication tokens
            signIn();
            }
        }

        return () => {
            isCleanedUp = true;
        };
    }, []);

    if (!isSignedIn) {
        return <Navigate to="/auth" />;
    }

    return children;
};

RequireAuth.propTypes = {
    children: element,
};

export default RequireAuth;
