import React, { useState } from "react";
import {
    array,
    bool,
    element,
    func,
    object,
    oneOfType,
    string,
} from "prop-types";
import { ButtonStyled } from "./Styles";

const Button = ({ type, children, iconLeft, iconRight, onClick, selected }) => {
    const [isActive, setIsActive] = useState(false);

    const getLeftIcon = () => {
        // If the left icon wasn't passed return an empty element
        if (!iconLeft) {
            return <></>;
        }

        return iconLeft;
    };

    const getRightIcon = () => {
        // If any right icon wasn't passed return an empty element
        if (!iconRight) {
            return <></>;
        }

        const defaultIconRight = iconRight.default || iconRight;
        const activeIconRight = iconRight.active || null;

        if (!isActive || !activeIconRight) {
            return defaultIconRight;
        }

        if (activeIconRight && isActive) {
            return activeIconRight;
        }
    };

    return (
        <ButtonStyled
            $type={type}
            onClick={() => {
                setIsActive(!isActive);
                onClick && onClick();
            }}
            $selected={selected}
        >
            {getLeftIcon()}
            {children}
            {getRightIcon()}
        </ButtonStyled>
    );
};

Button.propTypes = {
    type: string,
    children: oneOfType([string, element, array]),
    iconLeft: element,
    iconRight: oneOfType([element, object]),
    onClick: func,
    selected: bool,
};

export default Button;
