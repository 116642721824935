import React from "react";
import { bool, func, number, object, string } from "prop-types";
import Colors from "../../styles/Colors";
import Sizes from "../../styles/Sizes";
import { css, styled } from "styled-components";
import DayExpenseVisualization from "../DayExpenseVisualization";
import { formatByDigits } from "../../helpers/utils";
import { SortAsc, SortDesc } from "../icons";

const monoFont = `'PT Mono', monospace`;

const widthBreakpoints = ["640px", "560px", Sizes.widthMobileMax];

const Cell = styled.div`
    &:nth-child(1) {
        min-width: 128px;

        @container (width <= ${widthBreakpoints[0]}) {
            min-width: 88px;
        }
    }
    &:nth-child(2) {
        min-width: 96px;
    }
    &:nth-child(3) {
        flex-grow: 1;
    }
    &:nth-child(4) {
        min-width: 96px;
    }

    ${(props) =>
        props.onClick &&
        css`
            &:hover {
                cursor: pointer;

                ${Header} {
                    color: ${Colors.black};
                }
            }
        `}
`;

const Label = styled.div`
    color: ${Colors.greySecondaryText};
`;

const Long = styled.span``;

const Short = styled.span`
    display: none;
`;

const TodayIndicator = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: ${Colors.red0};
`;

const Date = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${Colors.greySecondaryText};

    @container (width <= ${widthBreakpoints[0]}) {
        gap: 4px;
        min-width: 80px;

        ${Long} {
            display: none;
        }

        ${Short} {
            display: inline;
        }
    }
`;

const Budget = styled.div`
    font-family: ${monoFont};
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`;

const Total = styled.div`
    font-family: ${monoFont};
    min-width: 72px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
`;

const Expenses = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    box-sizing: border-box;
    padding-left: 32px;

    @container (width <= ${widthBreakpoints[0]}) {
        padding-left: 16px;
    }
`;

const Balance = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-family: ${monoFont};
    color: ${Colors.green80};
    ${({ $negative }) => $negative && `color: ${Colors.red80};`}
`;

const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    border-radius: 4px;
    padding: 4px 8px;
    line-height: 20px;

    &:hover {
        background-color: ${Colors.grey3PercentBackground};
    }

    ${Label} {
        display: none;
    }

    @container (width <= ${widthBreakpoints[2]}) {
        flex-direction: column;
        padding: 8px;

        ${Cell} {
            display: flex;
            justify-content: space-between;
        }
        ${Label} {
            display: block;
        }
        ${Date} {
            flex-direction: row-reverse;
            gap: 12px;
        }
        ${Expenses} {
            padding: 0;
        }
        ${Total} {
            min-width: initial;
        }
    }
`;

const StyledDayExpenseListItem = styled.div`
    align-self: stretch;
    cursor: default;
    container-type: inline-size;

    ${({ $byTime }) => {
        switch ($byTime) {
            case "future":
                return css`
                    & ${Budget} {
                        color: ${Colors.greySecondaryText};
                    }
                `;
            case "today":
                return css`
                    & ${Date} {
                        color: ${Colors.black};
                    }
                `;
        }
    }}

    ${({ $order }) => $order && `order: ${$order};`}
`;

const DayExpenseListItem = ({
    dateString,
    dateStringShort,
    byTime,
    budget,
    expenses,
    balance,
    order,
}) => {
    return (
        <StyledDayExpenseListItem $byTime={byTime} $order={order}>
            <FlexWrapper>
                <Cell>
                    <Label>Дата</Label>
                    <Date>
                        <Long>{dateString}</Long>
                        <Short>{dateStringShort}</Short>
                        {byTime === "today" && <TodayIndicator />}
                    </Date>
                </Cell>
                <Cell>
                    <Label>На день</Label>
                    <Budget>
                        <span>{formatByDigits(budget)}</span>
                    </Budget>
                </Cell>
                <Cell>
                    <Label>Расходы</Label>
                    <Expenses>
                        {byTime !== "future" && (
                            <>
                                <DayExpenseVisualization
                                    list={expenses.list}
                                    total={expenses.total}
                                    normalizedByMaxTotal={
                                        expenses.normalizedByMaxTotal
                                    }
                                    compactViewBreakpoint={widthBreakpoints[1]}
                                />
                                <Total>{formatByDigits(expenses.total)}</Total>
                            </>
                        )}
                    </Expenses>
                </Cell>
                <Cell>
                    <Label>Сальдо</Label>
                    <Balance $negative={balance < 0}>
                        {byTime !== "future" && formatByDigits(balance)}
                    </Balance>
                </Cell>
            </FlexWrapper>
        </StyledDayExpenseListItem>
    );
};

DayExpenseListItem.propTypes = {
    dateString: string,
    dateStringShort: string,
    byTime: string,
    budget: number,
    expenses: object,
    balance: number,
    order: number,
};

const Header = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${Colors.greySecondaryText};
    ${({ $right }) => $right && `justify-content: flex-end;`}
`;

const DayExpenseListHeaders = ({
    dateSortingDescending = false,
    onDateSortingChange,
}) => {
    return (
        <StyledDayExpenseListItem>
            <FlexWrapper>
                <Cell onClick={onDateSortingChange}>
                    <Header>
                        дата
                        {dateSortingDescending ? <SortDesc /> : <SortAsc />}
                    </Header>
                </Cell>
                <Cell>
                    <Header $right>на день</Header>
                </Cell>
                <Cell>
                    <Header $right>расходы</Header>
                </Cell>
                <Cell>
                    <Header $right>сальдо</Header>
                </Cell>
            </FlexWrapper>
        </StyledDayExpenseListItem>
    );
};

DayExpenseListHeaders.propTypes = {
    dateSortingDescending: bool,
    onDateSortingChange: func,
};

export default DayExpenseListItem;

export { DayExpenseListHeaders };
