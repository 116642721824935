import { node } from "prop-types";
import React from "react";
import { styled } from "styled-components";
import Sizes from "../styles/Sizes";

const StyledLayout = styled.div`
    display: flex;
    padding: 40px;
    align-items: flex-start;
    gap: 48px;

    @media (width <= ${Sizes.widthDesktopNarrow1280}) {
        gap: 16px;
        flex-direction: column;
    }

    @media (width <= ${Sizes.widthMobileMax}) {
        padding: 16px;
    }
`;

const Layout = ({ children }) => <StyledLayout>{children}</StyledLayout>;

Layout.propTypes = {
    children: node,
};

export default Layout;
