import React from "react";
import GlobalStyles from "./styles/GlobalStyles";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Auth from "./pages/Auth";
import Home from "./pages/Home";
import { AuthProvider, RequireAuth } from "./components/auth";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <RequireAuth>
                <Home />
            </RequireAuth>
        ),
    },
    {
        path: "/auth",
        element: <Auth />,
    },
]);

const App = () => {
    return (
        <>
            <GlobalStyles />
            <AuthProvider>
                <RouterProvider router={router} />
            </AuthProvider>
        </>
    );
};

export default App;
